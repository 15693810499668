import { useNuxtApp } from "#imports";
import type { GrowthBookFeatureFlags, GrowthBookStringFeatureFlags } from "~/plugins/03.growthBook";

export function useFeatureFlags(): GrowthBookFeatureFlags {
  const nuxtApp = useNuxtApp();
  return nuxtApp.$featureFlags;
}

export function useStringFeatureFlags(): GrowthBookStringFeatureFlags {
  const nuxtApp = useNuxtApp();
  return nuxtApp.$stringFeatureFlags;
}
